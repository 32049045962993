export const LESSON_FORM = 'lessonForm';
export const TEMPORARY_SCHEDULE_FORM = 'temporaryScheduleForm';
export const TEMPORARY_SCHEDULE_VACATION_FORM = 'temporaryScheduleVacationForm';
export const GROUP_FORM = 'addGroup';
export const ROOM_FORM = 'AddRoom';
export const ROOM_FORM_TYPE = 'roomType';
export const FREE_ROOMS = 'FREE_ROOMS';
export const TEACHER_FORM = 'addTeacher';
export const STUDENT_FORM = 'addStudent';
export const CLASS_FORM = 'classForm';
export const LOGIN_FORM = 'loginForm';
export const REGISTRATION_FORM = 'registrationForm';
export const RESET_PASSWORD_FORM = 'resetPasswordForm';
export const SUBJECT_FORM = 'addSubject';
export const DEPARTMENT_FORM = 'addDepartment';
export const SEMESTER_FORM = 'addSemesterSuccess';
export const TEACHER_SCHEDULE_FORM = 'teacherScheduleForm';
export const SCHEDULE_SEARCH_FORM = 'SchedulePageForm';
export const PROFILE_FORM = 'ProfilePage';
export const SEMESTER_COPY_FORM = 'SemesterCopyForm';
export const COPY_LESSONS_FROM_SEMESTER_FORM = 'CopyLessonsFromSemesterForm';
