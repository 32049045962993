const cardType = {
    LESSON: 'Lesson',
    TEACHER: 'Teacher',
    CLASS: 'Class',
    GROUP: 'Group',
    ROOM: 'room',
    TYPE: 'type_delete',
    TEMPORARY_SCHEDULE: 'temporary_schedule',
    SUBJECT: 'Subject',
    SEMESTER: 'Semester',
};
export { cardType };
