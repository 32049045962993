export const HOME_PAGE_LINK = '/';
export const ADMIN_PAGE_LINK = '/admin';
export const SCHEDULE_PAGE_LINK = '/admin/schedules';
export const LOGIN_LINK = '/login';
export const ACTIVATION_PAGE_LINK = '/activation-page';
export const LOGOUT_LINK = '/logout';
export const TEACHER_SCHEDULE_LINK = '/teacher-schedule';
export const MY_PROFILE_LINK = '/my-profile';
export const LESSON_PAGE_LINK = '/admin/lessons';
export const TEACHER_LIST_LINK = '/admin/teachers';
export const GROUP_LIST_LINK = '/admin/groups';
export const GROUP_LIST_PARAM_LINK = '/admin/groups/:id?/:action?';
export const GROUP_LINK = '/group';
export const STUDENT_LINK = '/student';
export const EDIT_LINK = '/edit';
export const DELETE_LINK = '/delete';
export const ADD_STUDENT_LINK = '/add-student';
export const SET_DISABLE_LINK = '/set-disable';
export const SHOW_STUDENTS_LINK = '/show-students';
export const ID_PARAM_LINK = '/:id';
export const ID_STUDENT_PARAM_LINK = '/:idStudent';
export const CLASS_SCHEDULE_TITLE_LINK = '/admin/period';
export const ROOM_LIST_LINK = '/admin/rooms';
export const SUBJECT_PAGE_LINK = '/admin/subjects';
export const BUST_ROOMS_LINK = '/admin/busy-rooms';
export const SEMESTER_PAGE_LINK = '/admin/semesters';
export const MARGE_ROLE_PAGE_LINK = '/admin/users';
export const CHANGES_LINK = '/admin/changes';
export const SCHEDULE_FOR_LINK = '/schedule';
export const REGISTRATION_LINK = '/registration';
export const RESET_PASSWORD_LINK = '/reset-password';
export const DEPARTMENTS_LINK = '/admin/departments';
