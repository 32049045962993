export const CREATE_LESSON_CARD_SUCCESS = 'CREATE_LESSON_CARD_SUCCESS';
export const CREATE_LESSON_CARD_START = 'CREATE_LESSON_CARD_START';
export const HANDLE_LESSON_CARD_START = 'HANDLE_LESSON_CARD_START';
export const TOOGLE_TEACHER_START = 'TOOGLE_TEACHER_START';

export const GET_LESSONS_CARDS_START = 'GET_LESSONS_CARDS_START';
export const SET_LESSONS_CARDS_START = 'SET_LESSONS_CARDS_START';
export const GET_LESSON_TYPES_START = 'GET_LESSON_TYPES_START';
export const SET_LESSON_TYPES_SUCCESS = 'SET_LESSON_TYPES_SUCCESS';
export const COPY_LESSON_START = 'COPY_LESSON_START';

export const DELETE_LESSON_CARD_START = 'DELETE_LESSON_CARD_START';
export const DELETE_LESSON_CARD_SUCCESS = 'DELETE_LESSON_CARD_SUCCESS';

export const SELECT_LESSON_CARD_SUCCESS = 'SELECT_LESSON_CARD_SUCCESS';
export const UPDATE_LESSON_CARD_SUCCESS = 'UPDATE_LESSON_CARD_SUCCESS';
export const UPDATE_LESSON_CARD_START = 'UPDATE_LESSON_CARD_START';

export const SELECT_GROUP_ID = 'SELECT_GROUP_ID';
export const SET_UNIQUE_ERROR = 'SET_UNIQUE_ERROR';

export const SET_TEMPORARY_SCHEDULES = 'SET_TEMPORARY_SCHEDULES';
export const SET_SCHEDULES_AND_TEMPORARY_SCHEDULES = 'SET_SCHEDULES_AND_TEMPORARY_SCHEDULES';
export const SELECT_TEMPORARY_SCHEDULE = 'SELECT_TEMPORARY_SCHEDULE';
export const SELECT_VACATION = 'SELECT_VACATION';
export const SELECT_TEACHER_ID = 'SELECT_TEACHER_ID';

export const SET_OPEN_SNACKBAR = 'SET_OPEN_SNACKBAR';

export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const AUTH_USER_ERROR = 'AUTH_USER_ERROR';
export const AUTH_USER_INITIATE_LOGOUT = 'AUTH_USER_INITIATE_LOGOUT';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_USER_AUTO_LOGOUT = 'AUTH_USER_AUTO_LOGOUT';
export const AUTH_USER_CHECK_STATE = 'AUTH_USER_CHECK_STATE';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR';

export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_ERROR = 'ACTIVATE_USER_ERROR';

export const SET_LOADING_INDICATOR = 'SET_LOADING_INDICATOR';
export const SET_AUTH_LOADING_INDICATOR = 'SET_AUTH_LOADING_INDICATOR';
export const SET_SCHEDULE_LOADING_INDICATOR = 'SET_SCHEDULE_LOADING_INDICATOR';
export const SET_MAIN_SCHEDULE_LOADING_INDICATOR = 'SET_MAIN_SCHEDULE_LOADING_INDICATOR';
export const SET_SEMESTER_LOADING_INDICATOR = 'SET_SEMESTER_LOADING_INDICATOR';
export const SET_STUDENTS_LOADING_INDICATOR = 'SET_STUDENTS_LOADING_INDICATOR';
export const SET_ROOMS_LOADING_INDICATOR = 'SET_ROOMS_LOADING_INDICATOR';

export const GET_SCHEDULE_ITEMS_START = 'GET_SCHEDULE_ITEMS_START';
export const GET_SCHEDULE_ITEMS_SUCCESS = 'GET_SCHEDULE_ITEMS_SUCCESS';

export const SET_PLACE = 'SET_PLACE';
export const SELECT_GROUP_SCHEDULE_START = 'SELECT_GROUP_SCHEDULE_START';
export const SELECT_TEACHER_SCHEDULE_START = 'SELECT_TEACHER_SCHEDULE_START';
export const SELECT_FULL_SCHEDULE_START = 'SELECT_FULL_SCHEDULE_START';

export const CHECK_AVAILABILITY_CHANGE_ROOM_SCHEDULE_START =
    'CHECK_AVAILABILITY_CHANGE_ROOM_SCHEDULE_START';
export const CHECK_AVAILABILITY_CHANGE_ROOM_SCHEDULE_SUCCESS =
    'CHECK_AVAILABILITY_CHANGE_ROOM_SCHEDULE_SUCCESS';
export const GET_CURRENT_SEMESTER_START = 'GET_CURRENT_SEMESTER_START';
export const GET_CURRENT_SEMESTER_SUCCESS = 'GET_CURRENT_SEMESTER_SUCCESS';
export const GET_ALL_PUBLIC_TEACHERS_START = 'GET_ALL_PUBLIC_TEACHERS_START';
export const GET_ALL_PUBLIC_SEMESTERS_START = 'GET_ALL_PUBLIC_SEMESTERS_START';
export const GET_ALL_PUBLIC_SEMESTERS_SUCCESS =
    'GET_ALL_PUBLIC_SEMESTERS_SUCCESS';
export const GET_ALL_SCHEDULE_ITEMS_START = 'GET_ALL_SCHEDULE_ITEMS_START';
export const GET_ALL_PUBLIC_GROUPS_START = 'GET_ALL_PUBLIC_GROUPS_START';
export const SEND_TEACHER_SCHEDULE_START = 'SEND_TEACHER_SCHEDULE_START';
export const GET_TEACHER_RANGE_SCHEDULE_START =
    'GET_TEACHER_RANGE_SCHEDULE_START';
export const GET_TEACHER_RANGE_SCHEDULE_SUCCESS =
    'GET_TEACHER_RANGE_SCHEDULE_SUCCESS';
export const GET_DEFAULT_SEMESTER_START = 'GET_DEFAULT_SEMESTER_START';
export const GET_DEFAULT_SEMESTER_SUCCESS = 'GET_DEFAULT_SEMESTER_SUCCESS';
export const ADD_ITEM_TO_SCHEDULE = 'ADD_ITEM_TO_SCHEDULE';
export const ADD_ITEM_TO_SCHEDULE_START = 'ADD_ITEM_TO_SCHEDULE_START';
export const EDIT_ITEM_TO_SCHEDULE_START = 'EDIT_ITEM_TO_SCHEDULE_START';
export const DELETE_SCHEDULE_ITEM_START = 'DELETE_SCHEDULE_ITEM_START';
export const DELETE_SCHEDULE_ITEM_SUCCESS = 'DELETE_SCHEDULE_ITEM_SUCCESS';
export const CLEAR_SCHEDULE_START = 'CLEAR_SCHEDULE_START';
export const CHECK_AVAILABILITY_SCHEDULE_START =
    'CHECK_AVAILABILITY_SCHEDULE_START';
export const GET_FULL_SCHEDULE_START = 'GET_FULL_SCHEDULE_START';
export const GET_FULL_SCHEDULE_SUCCESS = 'GET_FULL_SCHEDULE_SUCCESS';
export const GET_GROUP_SCHEDULE_START = 'GET_GROUP_SCHEDULE_START';
export const GET_GROUP_SCHEDULE_SUCCESS = 'GET_GROUP_SCHEDULE_SUCCESS';
export const GET_TEACHER_SCHEDULE_START = 'GET_TEACHER_SCHEDULE_START';
export const GET_TEACHER_SCHEDULE_SUCCESS = 'GET_TEACHER_SCHEDULE_SUCCESS';
export const GET_BUSY_ROOMS_START = 'GET_BUSY_ROOMS_START';
export const GET_BUSY_ROOMS_SUCCESS = 'GET_BUSY_ROOMS_SUCCESS';
export const SET_SCHEDULE_TYPE = 'SET_SCHEDULE_TYPE';
export const DELETE_ITEM_FROM_SCHEDULE = 'DELETE_ITEM_FROM_SCHEDULE';
export const SET_ITEM_GROUP_ID = 'SET_ITEM_GROUP_ID';
export const GET_ALL_PUBLIC_TEACHERS_BY_DEPARTMENT_START =
    'GET_ALL_PUBLIC_TEACHERS_BY_DEPARTMENT_START';

export const ADD_TEACHER_SUCCESS = 'ADD_TEACHER_SUCCESS';
export const ADD_TEACHER_START = 'ADD_TEACHER_START';
export const HANDLE_TEACHER_START = 'HANDLE_TEACHER_START';
export const SELECT_TEACHER = 'SELECT_TEACHER';
export const SET_TEACHER = 'SET_TEACHER';
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_START = 'UPDATE_TEACHER_START';
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';
export const DELETE_TEACHER_START = 'DELETE_TEACHER_START';
export const SHOW_ALL_TEACHERS_SUCCESS = 'SHOW_ALL_TEACHERS_SUCCESS';
export const SHOW_ALL_TEACHERS_START = 'SHOW_ALL_TEACHERS_START';
export const GET_TEACHERS_WITHOUT_ACCOUNT_START =
    'GET_TEACHERS_WITHOUT_ACCOUNT_START';
export const GET_TEACHERS_WITHOUT_ACCOUNT_SUCCESS =
    'GET_TEACHERS_WITHOUT_ACCOUNT_SUCCESS';
export const GET_TEACHERS_BY_DEPARTMENT = 'GET_TEACHERS_BY_DEPARTMENT';

export const CLASS_FORM_HANDLER = 'CLASS_FORM_HANDLER';
export const ADD_CLASS_SCHEDULE_START = 'ADD_CLASS_SCHEDULE_START';
export const GET_CLASS_SCHEDULE_LIST_START = 'GET_CLASS_SCHEDULE_LIST_START';
export const GET_CLASS_SCHEDULE_BY_ID_START = 'GET_CLASS_SCHEDULE_BY_ID_START';
export const DELETE_CLASS_SCHEDULE_START = 'DELETE_CLASS_SCHEDULE_START';
export const UPDATE_CLASS_SCHEDULE_START = 'UPDATE_CLASS_SCHEDULE_START';
export const CLEAR_CLASS_SCHEDULE_START = 'CLEAR_CLASS_SCHEDULE_START';
export const GET_PUBLIC_CLASS_SCHEDULE_LIST_START =
    'GET_PUBLIC_CLASS_SCHEDULE_LIST_START';
export const ADD_CLASS_SCHEDULE_SUCCESS = 'ADD_CLASS_SCHEDULE_SUCCESS';
export const GET_CLASS_SCHEDULE_LIST_SUCCESS =
    'GET_CLASS_SCHEDULE_LIST_SUCCESS';
export const GET_CLASS_SCHEDULE_BY_ID_SUCCESS =
    'GET_CLASS_SCHEDULE_BY_ID__SUCCESS';
export const DELETE_CLASS_SCHEDULE_SUCCESS = 'DELETE_CLASS_SCHEDULE_SUCCESS';
export const UPDATE_CLASS_SCHEDULE_SUCCESS = 'UPDATE_CLASS_SCHEDULE_SUCCESS';
export const CLEAR_CLASS_SCHEDULE_SUCCESS = 'CLEAR_CLASS_SCHEDULE_SUCCESS';
export const GET_PUBLIC_CLASS_SCHEDULE_LIST_SUCCESS =
    'GET_PUBLIC_CLASS_SCHEDULE_LIST_SUCCESS';

export const ADD_ROOM = 'ADD_ROOM';
export const ADD_ROOM_START = 'ADD_ROOM_START';
export const DELETE_ROOM = 'DELETE_ROOM';
export const DELETE_ROOM_START = 'DELETE_ROOM_START';
export const SHOW_LIST_OF_ROOMS_SUCCESS = 'SHOW_LIST_OF_ROOMS_SUCCESS';
export const GET_LIST_OF_ROOMS_START = 'GET_LIST_OF_ROOMS_START';
export const GET_LIST_OF_DISABLED_ROOMS_START =
    'GET_LIST_OF_DISABLED_ROOMS_START';
export const SET_SELECT_ROOM = 'SET_SELECT_ROOM';
export const UPDATE_ROOM = 'UPDATE_ROOM';
export const UPDATE_ROOM_START = 'UPDATE_ROOM_START';
export const CLEAR_ROOM = 'CLEAR_ROOM';
export const GET_ALL_ROOM_TYPES = 'GET_ALL_ROOM_TYPES';
export const GET_ALL_ROOM_TYPES_START = 'GET_ALL_ROOM_TYPES_START';
export const ADD_ROOM_TYPE = 'ADD_ROOM_TYPE';
export const DELETE_ROOM_TYPE = 'DELETE_ROOM_TYPE';
export const DELETE_ROOM_TYPE_START = 'DELETE_ROOM_TYPE_START';
export const SELECT_ROOM_TYPE = 'SELECT_ROOM_TYPE';
export const UPDATE_ROOM_TYPE = 'UPDATE_ROOM_TYPE';
export const CLEAR_ONE_NEW_TYPE = 'CLEAR_ONE_NEW_TYPE';
export const GET_FREE_ROOMS_START = 'GET_FREE_ROOMS_START';
export const GET_FREE_ROOMS_SUCCESS = 'GET_FREE_ROOMS_SUCCESS';
export const CLEAR_FREE_ROOM = 'CLEAR_FREE_ROOM';
export const HANDLE_ROOM_FORM_SUBMIT_START = 'HANDLE_ROOM_FORM_SUBMIT_START';
export const HANDLE_ROOM_TYPE_FORM_SUBMIT_START =
    'HANDLE_ROOM_TYPE_FORM_SUBMIT_START';
export const TOGGLE_ROOM_VISIBILITY_START = 'TOGGLE_ROOM_VISIBILITY_START';
export const CLEAR_FREE_ROOMS = 'CLEAR_FREE_ROOMS';

export const ADD_SUBJECT = 'ADD_SUBJECT';
export const DELETE_SUBJECT = 'DELETE_SUBJECT';
export const SHOW_ALL_SUBJECTS = 'SHOW_ALL_SUBJECT';
export const SELECT_SUBJECT = 'SELECT_SUBJECT';
export const UPDATE_SUBJECT = 'UPDATE_SUBJECT';
export const CLEAR_SUBJECT = 'CLEAR_SUBJECT';

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS';
export const CLEAR_DEPARTMENT_FORM = 'CLEAR_DEPARTMENT_FORM';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';
export const GET_DISABLED_DEPARTMENTS = 'GET_DISABLED_DEPARTMENTS';
export const SET_DISABLED_DEPARTMENT = 'SET_DISABLED_DEPARTMENT';
export const SET_ENABLED_DEPARTMENT = 'SET_ENABLED_DEPARTMENT';
export const GET_DEPARTMENT_BY_ID = 'GET_DEPARTMENT_BY_ID';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';

export const SET_SCHEDULE_GROUP = 'SET_SCHEDULE_GROUP';

export const ADD_SEMESTER_SUCCESS = 'ADD_SEMESTER_SUCCESS';
export const ADD_SEMESTER_START = 'ADD_SEMESTER_START';
export const DELETE_SEMESTER_SUCCESS = 'DELETE_SEMESTER_SUCCESS';
export const DELETE_SEMESTER_START = 'DELETE_SEMESTER_START';
export const SHOW_ALL_SEMESTERS_SUCCESS = 'SHOW_ALL_SEMESTER';
export const GET_ALL_SEMESTERS_START = 'GET_ALL_SEMESTER_START';
export const SELECT_SEMESTER_SUCCESS = 'SELECT_SEMESTER_SUCCESS';
export const UPDATE_SEMESTER_SUCCESS = 'UPDATE_SEMESTER_SUCCESS';
export const UPDATE_SEMESTER_START = 'UPDATE_SEMESTER_START';
export const UPDATE_SEMESTER_BY_ID_START_SUCCESS =
    'UPDATE_SEMESTER_BY_ID_START_SUCCESS';
export const HANDLE_SEMESTER_FORM_SUBMIT_START =
    'HANDLE_SEMESTER_FORM_SUBMIT_START';
export const SET_SEMESTER_COPY_START = 'SEMESTER_COPY_START';
export const COPY_LESSONS_FROM_SEMESTER_START =
    'COPY_LESSONS_FROM_SEMESTER_START';
export const CLEAR_SEMESTER_SUCCESS = 'CLEAR_SEMESTER_SUCCESS';
export const SET_ERROR = 'SET_ERROR';
export const SET_ARCHIVED_SEMESTERS_SUCCESS = 'SET_ARCHIVED_SEMESTERS_SUCCESS';
export const SET_ARCHIVED_SEMESTERS_START = 'SET_ARCHIVED_SEMESTERS_START';
export const GET_ARCHIVE_SEMESTER_BY_ID_START =
    'GET_ARCHIVE_SEMESTER_BY_ID_START';
export const CREATE_ARCHIVE_SEMESTER_START = 'CREATE_ARCHIVE_SEMESTER_START';
export const SET_GROUPS_TO_SEMESTER_START = 'SET_GROUPS_TO_SEMESTER_START';
export const TOGGLE_SEMESTER_VISIBILITY_START =
    'TOGGLE_SEMESTER_VISIBILITY_START';

export const SET_DISABLED_ROOMS = 'SET_DISABLED_ROOMS';
export const SET_DISABLED_TEACHERS_SUCCESS = 'SET_DISABLED_TEACHERS_SUCCESS';
export const SET_DISABLED_TEACHERS_START = 'SET_DISABLED_TEACHERS_START';
export const SET_DISABLED_GROUPS = 'SET_DISABLED_GROUPS';
export const SET_DISABLED_SEMESTERS_SUCCESS = 'SET_DISABLED_SEMESTERS_SUCCESS';
export const GET_DISABLED_SEMESTERS_START = 'GET_DISABLED_SEMESTERS_START';
export const SET_DISABLED_SUBJECTS = 'SET_DISABLED_SUBJECTS';

export const SET_SCHEDULE_TEACHER = 'SET_SCHEDULE_TEACHER';
export const SET_SCHEDULE_SEMESTER = 'SET_SCHEDULE_SEMESTER';
export const SET_TEACHER_VIEW_TYPE = 'SET_TEACHER_VIEW_TYPE';
export const MOVE_SEMESTER_TO_ARCHIVE_SUCCESS =
    'MOVE_SEMESTER_TO_ARCHIVE_SUCCESS';

export const SET_IS_OPEN_CONFIRM_DIALOG = 'SET_IS_OPEN_CONFIRM_DIALOG';

export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const SHOW_ALL_GROUPS_SUCCESS = 'SHOW_ALL_GROUPS_SUCCESS';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const SELECT_GROUP_SUCCESS = 'SELECT_GROUP_SUCCESS';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CLEAR_GROUP_SUCCESS = 'CLEAR_GROUP_SUCCESS';

export const TOGGLE_DISABLED_STATUS_GROUP = 'TOGGLE_DISABLED_STATUS_GROUP';
export const DRAG_AND_DROP_GROUP_START = 'DRAG_AND_DROP_GROUP_START';
export const GET_DISABLED_GROUPS_START = 'GET_DISABLED_GROUPS_START';
export const GET_ENABLED_GROUPS_START = 'GET_ENABLED_GROUPS_START';
export const GET_GROUP_BY_ID_START = 'GET_GROUP_BY_ID_START';
export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const SUBMIT_GROUP_START = 'SUBMIT_GROUP_START';
export const CLEAR_GROUP_START = 'CLEAR_GROUP_START';

export const SHOW_ALL_STUDENTS_BY_GROUP_ID = 'SHOW_ALL_STUDENTS_BY_GROUP_ID';
export const SHOW_ALL_STUDENTS = 'SHOW_ALL_STUDENTS';
export const DELETE_STUDENT = 'DELETE_STUDENT';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const CREATE_STUDENT = 'CREATE_STUDENT';
export const SET_STUDENT = 'SET_STUDENT';

export const MOVE_STUDENTS_TO_GROUP_START = 'MOVE_STUDENTS_TO_GROUP_START';
export const UPLOAD_FILE_STUDENT_START = 'UPLOAD_FILE_STUDENT_START';
export const CREATE_STUDENT_START = 'CREATE_STUDENT_START';
export const UPDATE_STUDENT_START = 'UPDATE_STUDENT_START';
export const DELETE_STUDENT_START = 'DELETE_STUDENT_START';
export const SUBMIT_STUDENT_FORM = 'SUBMIT_STUDENT_FORM';
export const DELETE_SELECTED_STUDENTS = 'DELETE_SELECTED_STUDENTS';
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const CHECK_ALL_STUDENTS = 'CHECK_ALL_STUDENTS';
